<script>
    import { onMount } from 'svelte';

    let manufacturers, textiles, colors, series, results, _results = []

    let currentManufacturer, currentTextile, currentColor, currentSeries = ''
    let canReset = false

    onMount(async () => {
        let url = new URL('https://viawebsite.blob.core.windows.net/viaseating/_mailin/textile-report.json');

        await fetch(url.toString(), {
            method: 'GET'
        })
            .then((response) => response.json())
            .then((data) => {
                manufacturers = data.filters.manufacturers
                textiles = data.filters.textiles
                colors = data.filters.colors
                series = data.filters.series
                results = data.data
                _results = data.data
            })
            .catch((error) => {
                console.error(error)
            });
    })

    function filterResults() {
        let filtered = [];
        for(let i = 0; i < results.length; i++) {
            if(results[i].manufacturer === currentManufacturer || results[i].other === currentManufacturer || currentManufacturer === '') {
                if(results[i].textile === currentTextile || currentTextile === '') {
                    if(results[i].series === currentSeries || currentSeries === '' || results[i].series === 'ALL') {
                        filtered.push(results[i])
                    }
                }
            }
        }

        _results = filtered

        canReset = _results.length !== results.length;
    }

    function resetResults() {
        currentManufacturer = currentTextile = currentColor = currentSeries = ''
        _results = results
        canReset = false
    }

    function manufacturer(result) {
        if(result.manufacturer == 'Other (please type below)') {
            return result.other
        }
        return result.manufacturer
    }
</script>

<div class="w-full mx-auto mb-12">
    <div class="flex items-center justify-start mb-4">
        <div class="mr-4">
            <select bind:value={ currentManufacturer } class="text-sm w-full form-select">
                <option value="" selected>Mill</option>
                {#if manufacturers }
                    {#each manufacturers as item}
                        <option value="{item}">{item}</option>
                    {/each}
                {/if}
            </select>
        </div>

        <div class="mr-4">
            <select bind:value={ currentTextile } class="text-sm w-full form-select">
                <option value="" selected>Textiles</option>
                {#if textiles }
                    {#each textiles as item}
                        <option value="{item}">{item}</option>
                    {/each}
                {/if}
            </select>
        </div>

        <div class="mr-4">
            <select bind:value={ currentSeries } class="text-sm w-full form-select">
                <option value="" selected>Series</option>
                <option value="ALL">ALL</option>
                {#if series }
                    {#each series as item}
                        <option value="{item}">{item}</option>
                    {/each}
                {/if}
            </select>
        </div>

        <div class="mr-4">
            <a class="bg-modal p-2 text-white form-button" on:click="{ () => filterResults() }">Search</a>
        </div>

        {#if canReset}
        <div>
            <a class="bg-modal p-2 text-white form-button" on:click="{ () => resetResults() }">Reset Filters</a>
        </div>
        {/if}
    </div>

    {#if canReset && _results.length > 0 }
    <table class="table-fixed w-full text-sm">
        <thead>
            <tr class="text-left divide-x">
                <th class="w-1/8 px-1 py-2 border-b-2">Mill</th>
                <th class="w-1/8 px-1 py-2 border-b-2">Textile</th>
                <th class="w-1/8 px-1 py-2 border-b-2">Series</th>
                <th class="w-1/8 px-1 py-2 border-b-2">Model Number</th>
                <th class="w-1/8 px-1 py-2 border-b-2">Result</th>
                <th class="w-2/8 px-1 py-2 border-b-2">Notes</th>
            </tr>
        </thead>
        <tbody class="divide-y">
            {#each _results as result}
            <tr class="divide-x">
                <td class="w-1/8 px-1 py-2 align-top">{manufacturer(result)}</td>
                <td class="w-1/8 px-1 py-2 align-top">{result.textile}</td>
                <td class="w-1/8 px-1 py-2 align-top">{result.series}</td>
                <td class="w-1/8 px-1 py-2 align-top">{result.model_number}</td>
                <td class="w-1/8 px-1 py-2 align-top">{result.status}</td>
                <td class="w-3/8 px-1 py-2 align-top">{result.notes}</td>
            </tr>
            {/each}
        </tbody>
    </table>
    {:else}
        <div class="w-1/2 mx-auto">
            <img src="https://viawebsite.blob.core.windows.net/viaseating/thumbnail_search-criteria.jpg" alt="Please provide search criteria for results." />
        </div>
    {/if}
</div>